@import '../../../../scss/components/variable';

.footer-top-formatter-uk-v3 {
  .flex-container {
    display: flex;
    > div {
      margin: 0;
    }
    .btn:hover {
      .a {
        border: 0;
        text-decoration: none;
        outline: 0;
      }
    }
    &__first {
      padding: 2% 2% 2% 0;
      flex-grow: 1;
      img {
        max-width: 100px;
      }
    }
    &__center {
      border-style: solid;
      border-color: $color--sonic-silver;
      border-width: 0 thin 0 thin;
      justify-content: center;
      padding: 2%;
      flex-grow: 1;
      img {
        max-width: 48px;
      }
    }
    &__last {
      padding: 2% 0 2% 2%;
      flex-grow: 1;
      img {
        max-width: 71px;
      }
    }
    div.btn {
      a {
        border-bottom: 0px;
      }
    }
  }
  .site-footer--top & {
    .block--footer-mobile-help {
      .btn {
        border-bottom: 0;
        display: block;
        font-size: 16px;
        height: auto;
        line-height: normal;
        margin: 0;
        padding: 0;
        &:hover {
          border: 0;
        }
      }
    }
  }
  .wa_uk_footer {
    text-decoration: none;
  }
  .btn--clear-bg {
    background-color: transparent;
    border-width: 0px;
  }
}
// We had to use !important because the below styles are sent to
// us by LivePerson as inline and we have no control over that
.LPMcontainer {
  border: none;
  margin: 0 !important;
  &.LPMoverlay {
    .flex-livechat & {
      height: auto !important;
      width: auto !important;
    }
    img {
      top: inherit !important;
      left: inherit !important;
      position: relative !important;
    }
  }
  div {
    a {
      outline: 0;
      text-decoration: none;
      border: none;
      background-color: transparent;
    }
  }
}
